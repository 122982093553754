import Error from "@@/common/Error.vue";
import cont_match_reg from "@/utils/editor.js";
import { getProjectBasicContent, getQueryUserLicenseInfo3c } from "@api/index";
import { observeVideo } from "md-base-tools/editor/gitvideo";
import { mdToast } from "@/utils/tools";
export default {
  components: {
    Error
  },
  data() {
    return {
      pro_id: '',
      showError: false,
      showMessage: "",
      content: '',
      risk: '',
      urgent_notices: '',
      certificate_ccc_id: ''
    };
  },
  async mounted() {
    const pro_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    this.pro_id = pro_id;
    const {
      data,
      status,
      message
    } = await getProjectBasicContent({
      pro_id: pro_id
    });
    if (status == 0 && data) {
      const product_info = data || {};
      this.content = product_info ? product_info.content : '';
      this.risk = product_info ? product_info.risk : '';
      this.urgent_notices = product_info ? product_info.urgent_notices : '';
      this.certificate_ccc_id = product_info ? product_info.certificate_ccc_id : '';
    } else if (status == '999999') {
      this.showError = true;
      this.showMessage = "网络错误";
      this.sensors.track('ProjectClientDetail', {
        Type: "网络错误"
      });
    } else {
      this.showError = true;
      this.showMessage = message || "什么都没有哦";
      this.sensors.track('ProjectClientDetail', {
        Type: "接口错误",
        Message: message
      });
    }
    const _this = this;
    _this.$nextTick(() => {
      if (!_this.showError && _this.$refs.editorBox) {
        _this.$refs.editorBox.appendChild(cont_match_reg(_this.content));
        _this.$nextTick(() => {
          observeVideo(_this.$refs.editorBox, process.env.VUE_APP_ENV);
        });
      }
    });
  },
  methods: {
    async showCertificateCcc() {
      const {
        data,
        status,
        message
      } = await getQueryUserLicenseInfo3c({
        pro_id: this.pro_id
      });
      if (status == 0 && data && data.list && data.list.length) {
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "onImageClicked",
          functionParams: {
            imageUrls: data.list.map(item => item.file_url),
            imageIndex: 0
          }
        });
      } else {
        mdToast(message);
      }
    }
  }
};